/***********************************************
Font-face setup
***********************************************/
@font-face {
  font-family:'Maven Pro';
  src:url('/font/mavenpro-regular.eot');
  src:url('/font/mavenpro-regular.eot?#iefix') format('embedded-opentype'),
    url('/font/mavenpro-regular.woff') format('woff'),
    url('/font/mavenpro-regular.ttf') format('truetype'),
    url('/font/mavenpro-regular.svg#Maven Pro') format('svg');
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family:'Maven Pro';
  src:url('/font/mavenpro-medium.eot');
  src:url('/font/mavenpro-medium.eot?#iefix') format('embedded-opentype'),
    url('/font/mavenpro-medium.woff') format('woff'),
    url('/font/mavenpro-medium.ttf') format('truetype'),
    url('/font/mavenpro-medium.svg#Maven Pro') format('svg');
  font-weight:500;
  font-style:normal;
}

@font-face {
  font-family:'Maven Pro';
  src:url('/font/mavenpro-bold.eot');
  src:url('/font/mavenpro-bold.eot?#iefix') format('embedded-opentype'),
    url('/font/mavenpro-bold.woff') format('woff'),
    url('/font/mavenpro-bold.ttf') format('truetype'),
    url('/font/mavenpro-bold.svg#Maven Pro') format('svg');
  font-weight:700;
  font-style:normal;
}

// html                                            { font-size:16px; }
body                                            { font-family:'Maven Pro', sans-serif; line-height:1.3; color:#1A2730; background-color:#FFF; height:100%; overflow-x:hidden; transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }

pre + *                                         { margin-top:3rem; }
pre code                                        { background-color:#444; border:none; }

[class^="columns"] + h2,
[class^="columns"] + h3,
[class^="columns"] + h4,
[class^="columns"] + h5,
[class^="columns"] + h6                         { margin-top:80px; }
h6 + pre                                        { margin-top:40px; }

.column                                         { position:relative; }

.cover                                          { position:absolute; top:0; right:0; bottom:0; left:0; z-index:10; }

.icon                                           { top:0; }

/***********************************************
Header
***********************************************/
.header                                         { margin:0 0 0; padding:0; min-height:300px; width:100%; text-align:center; background-image:url('/img/bg-01.jpg'); background-size:cover; background-position:center; background-repeat:no-repeat; position:relative; top:0; left:0; display:flex; align-items:center; }
.header .btn                                    { margin-bottom:100px; }

.section                                        { padding:60px 0; position:relative; }
.section--stripe                                { background-color:#F6F5F7; }
.section--features                              { background-image:url('/img/bg-02.png'); background-position:center; background-repeat:no-repeat; }

/***********************************************
Main Navigation
***********************************************/
.navigation                                     { padding:0; background-color:transparent; display:table; width:100%; position:absolute; top:0; left:0; z-index:10; }

.navigation-wrapper                             { padding:0 30px 0 90px; }

.navigation .logo                               { width:30px; height:30px; display:block; position:absolute; left:30px; top:25px; }
.navigation .logo .icon                         { color:#333; fill:#333; text-decoration:none; width:30px; height:30px; }
.navigation .logo-mobile                        { display:none; }

.navigation ol                                  { margin:28px 0 0 0; padding:0; list-style:none; }
.navigation ol li                               { margin:0 30px 0 0; display:block; float:left; color:#999; }
.navigation ol li:last-child                    { margin-right:0; }

.navigation ol li a                             { color:#333; fill:#333; text-decoration:none; }
.navigation ol li a:hover,
.navigation ol li a.active                      { color:#A88ABF; }

.navigation ol li ol                            { display:none; }
.navigation ol li a.active + ol                 { display:block; }

.navigation ol li ol                            { position:absolute; top:235px; left:0; text-align:center; width:100%; }
.navigation ol li ol li                         { margin:0; line-height:inherit; float:none; display:inline-block; }
.navigation ol li ol li a                       { padding:10px 30px; height:auto; display:block; border-radius:3px 3px 0 0; }
.navigation ol li ol li a.active                { background-color:#FFF; }

.navigation ol.nav-left                         { float:left; }
.navigation ol.nav-right                        { float:right; }

.mobile_nav                                     { margin:0; padding:0 20px; display:none; height:60px; width:60px; text-align:center; border:none; background-color:transparent; cursor:pointer; -webkit-appearance:none; position:absolute; top:10px; right:10px; z-index:10; }
.mobile_nav span                                { width:20px; height:4px; background-color:#000; display:block; transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }
.mobile_nav span:before                         { content:''; width:20px; height:4px; background-color:#000; display:block; position:absolute; top:22px; left:20px; transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }
.mobile_nav span:after                          { content:''; width:20px; height:4px; background-color:#000; display:block; position:absolute; bottom:22px; left:20px; transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }

.menu_open .mobile_nav                          { position:fixed; }
.menu_open .mobile_nav span                     { background-color:transparent; transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }
.menu_open .mobile_nav span:before              { background-color:#FFF; top:28px; -webkit-transform:rotate(40deg); -moz-transform:rotate(40deg); -ms-transform:rotate(40deg); -o-transform:rotate(40deg); transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }
.menu_open .mobile_nav span:after               { background-color:#FFF; bottom:28px; -webkit-transform:rotate(-40deg); -moz-transform:rotate(-40deg); -ms-transform:rotate(-40deg); -o-transform:rotate(-40deg); transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -moz-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -webkit-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; -o-transition:all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s; }

.aside                                          { padding:0; width:300px; height:100%; background-color:#303d4c; position:absolute; top:0; left:0; overflow:hidden; z-index:11;
  .logo                                         { padding:23px 0 0 23px; height:70px; display:block;
    .icon                                       { color:#FFF; fill:#FFF; text-decoration:none; width:30px; height:30px; }
  }

  ol                                            { margin:0; padding:0; list-style:none; width:350px; height:calc(100% - 70px); position:relative; top:0; left:0; overflow-y:auto;
    li                                          { margin:0; padding:0 60px 0 10px; font-size:14px; color:#FFF;
      &.toggle-closed ol                        { display:none; }
      &.toggle-opened                           {
        ol                                      { display:block; }
        .vertical                               { transform:scaleY(0); }
      }
      .btn                                      { margin:0; text-align:left; color:#FFF; fill:#FFF;
        svg                                     { position:absolute; top:15px; right:10px; }
      }
      ol                                        { margin:0 0 20px; width:100%; position:relative;
        li                                      {
          a                                     { padding:6px 0 0; display:block; color:#acb1b7; fill:#acb1b7; text-decoration:none;
            &:hover                             { color:#FFF; }
          }
        }
      }
    }
  }
}

.section--cheatsheet                            { padding:50px 20px 50px 320px; width:100%; height:100%; background-color:#FFF; position:absolute; top:0; left:0; z-index:10;
  pre + h2                                      { margin-top:60px; }
}


/***********************************************
Footer Navigation
***********************************************/
.bottom-navigation                              { margin:0; padding:0; list-style:none; overflow:hidden; }
.bottom-navigation li                           { margin:0; display:block; float:left; color:#999; }
.bottom-navigation li:last-child                { float:right; }
.bottom-navigation li:first-child               { float:left; }

.icon--size-xxl                                 { margin:100px 0 20px; height:140px; width:140px; fill:#333; }

/***********************************************
Demos
***********************************************/
.demo span                                      { padding:6px; width:100%; display:block; text-align:center; font-size:12px; background-color:#EEE; border-radius:3px; }
.demo .row span                                 { background-color:#DDD; }

.demo small                                     { display:block; color:#BBB; font-size:14px; line-height:16px; font-weight:normal; }

.demo img                                       { width:100%; height:auto; }

.typeface                                       { margin:0 0 20px; padding:0; list-style:none; display:flex; align-items:stretch; flex-direction:row; flex-wrap:wrap; flex-flow:row wrap; align-content:flex-end; }
.typeface li                                    { margin:0; padding:0; float:left; font-size:20px; line-height:50px; width:calc(100% / 26); }

.icon + h4,
h3 + [class^="gridded"],
[class^="gridded"] + pre                        { margin-top:50px; }

.demo.box                                       { margin:0 0 10px;
  .content                                      { font-size:14px; background-color:#EEE; border-radius:3px; display:flex; align-items:center; justify-content:center; }
}

/***********************************************
Colors
***********************************************/
.color                                          { padding:20px 0; display:block; width:100%; text-align:center; font-size:14px; color:#FFF; letter-spacing:1px; display:flex; align-items:center; justify-content:center; border-radius:3px; }
.color span                                     { display:block; }

.color--layer-top                               { border-radius:3px 3px 0 0; }
.color--layer-middle                            { border-radius:0; }
.color--layer-bottom                            { border-radius:0 0 3px 3px; }

.color--bg-smoke                                { background-color:#F0F2F4; color:#33424D; }
.color--bg-smokeDark                            { background-color:#D8D9DB; color:#33424D; }
.color--bg-smokeExtraDark                       { background-color:#C0C2C3; color:#33424D; }

.color--bg-snow                                 { background-color:#FFF; color:#33424D; border:1px solid #E5E5E5; border-bottom:0; }
.color--bg-snowDark                             { background-color:#F7F7F7; color:#33424D; border-left:1px solid #E5E5E5; border-right:1px solid #E5E5E5; }
.color--bg-snowExtraDark                        { background-color:#E5E5E5; color:#33424D; border:1px solid #E5E5E5; border-top:0; }

.color--bg-brand                                { background-color:#A88ABF; }
.color--bg-brandDark                            { background-color:#1A2730; }

.color--bg-black                                { background-color:#1A2730; }
.color--bg-slate                                { background-color:#26343E; }
.color--bg-steel                                { background-color:#33424D; }
.color--bg-silver                               { background-color:#6A8092; }

.color--bg-positive                             { background-color:#29B765; }
.color--bg-positiveDark                         { background-color:#219358; }

.color--bg-warning                              { background-color:#FFC82C; color:#33424D; }
.color--bg-warningDark                          { background-color:#F8B700; color:#33424D; }

.color--bg-negative                             { background-color:#E74C3C; }
.color--bg-negativeDark                         { background-color:#B93D30; }

.color--bg-info                                 { background-color:#3498DB; }
.color--bg-infoDark                             { background-color:#206FA4; }

/***** Color - Brand *****/
.btn--color-brand,
.btn--color-brand:hover[disabled]               { background-color:#A88ABF; border-color:#A88ABF; }
.btn--color-brand:hover                         { background-color:#806A90; border-color:#806A90; }


/***********************************************
Prototype Page styling
***********************************************/
.screenshot                                     { margin:0 0 20px; padding:20px 0 0; display:block; background-color:#DDD; border-radius:3px; position:relative; }
.screenshot:before                              { content:''; width:100%; height:20px; background-color:#26343E; background-position:5px 6px; background-repeat:no-repeat; background-image:url('../../img/window-controls.png'); border-radius:3px 3px 0 0; position:absolute; top:0; left:0; }
.screenshot img                                 { width:100%; height:auto; }

.icon--size-large                               { font-size:32px; }

/***********************************************
Footer
***********************************************/
.footer                                         { margin:100px 0 0; padding:20px; height:62px; background-color:#F3F3F3; overflow:hidden; }
.footer p                                       { margin:0; font-size:13px; }
.footer a                                       { color:#222; text-decoration:none; }
.footer a:hover                                 { fill:#888; color:#888; }

/***********************************************
Medium
***********************************************/
@media only screen and (max-width:768px)        {
  .header                                       { margin:0 0 20px; }
  .section                                      { padding:10px 0; }
  .footer                                       {  margin:20px 0 0; padding:20px 30px; height:auto; }
}


/***********************************************
Mobile
***********************************************/
@media only screen and (max-width:767px) {

  .txt-left-mobile                              { text-align:left; }

  .navigation-wrapper                           { padding:40px; display:none; width:100%; height:100%; background-color:rgba(0,0,0,.93); position:fixed; top:0; left:0; z-index:9; }

  .navigation .logo-mobile                      { display:block; fill:#FFF;  }
  .navigation .logo-mobile .icon                { margin:0 0 20px; height:50px; width:50px; }

  .navigation ol li                             { margin:0; line-height:40px; float:none; font-size:14px; color:#FFF; letter-spacing:1px; text-align:center; }

  .navigation ol li a                           { margin:0 0 10px; color:#FFF; fill:#FFF; border:1px solid #666; display:none; }
  .navigation ol li:first-child a               { display:block; }

  .navigation ol.nav-left,
  .navigation ol.nav-right                      { margin:0; float:none; }

  .navigation ol li ol                          { margin:0; display:block; position:relative; top:0; }
  .navigation ol li ol li                       { display:block; }
  .navigation ol li ol li a                     { padding:0; border-radius:0; display:block; }
  .navigation ol li ol li a.active              { background-color:transparent; }

  .mobile_nav                                   { display:inline-block; }

  .menu_open .navigation-wrapper                { display:block; }

  .header                                       { margin:0 0 20px; height:100vh; }
  .section                                      { padding:20px 0; }

  .bottom-navigation li,
  .bottom-navigation li:last-child              { float:left; width:100%; }
  .bottom-navigation .btn                       { display:block; width:100%; }

  .typeface li                                  { width:30px; line-height:30px; }

  .footer                                       { margin:20px 0 0; padding:20px 0; height:auto; }
  .footer .float--left,
  .footer .float--right                         { float:none; text-align:center; }
}

/***********************************************
Mobile (landscape 420px)
***********************************************/
@media only screen and (min-width:480px) and (max-width:767px) {
  .navigation ol li                           { float:left; width:50%; }
}
